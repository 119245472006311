<template>
<v-row>
  <v-col cols="12" sm="12">
      <lookup-dominio label="* Tipo"
                      :hidden-clear="true"
                      hint="Selecione o Tipo"
                      v-model="value.idTipo"
                      :type="lookups.tipoItem"
                      />
  </v-col> 
  <v-col cols="12" sm="12">
    <v-text-field label="* Descricao"
                  dense
                  :rules="[
                      this.$validators.string.required,
                           v => this.$validators.string.greaterThanOrEquals(v, 1),
                      v => this.$validators.string.lessThanOrEquals(v, 200)
                  ]"
                  v-model.trim="value.descricao"/>
  </v-col>
</v-row>
</template>

<script>
import {ItemDeDominio} from "../../../api/itemsDominio";
import LookupDominio from "../common/LookupDominio";
import SearchBox from "../common/SearchBox";

export default {
  name: "ItemContratoForm",
  components: {SearchBox, LookupDominio},  
  props: {
    value: {
      type: Object
    }
  },
  data() {
    return {
      lookups: {
        tipoItem: ItemDeDominio.TIPO_ITEM_CONTRATO,
      },
    }
  },  
}
</script>

<style scoped>

</style>